import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Pictures from "../DetailsComponents/Pictures";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BottomPictures from "../DetailsComponents/BottomPictures";
import Description from "../DetailsComponents/Description";
import StrongPoint from "../DetailsComponents/StrongPoint";
import img1 from "../../../assets/Image/Details5/Photo1.jpg";
import img2 from "../../../assets/Image/Details5/Photo2.jpg";
import img3 from "../../../assets/Image/Details5/Photo3.jpg";
import img4 from "../../../assets/Image/Details5/Photo4.jpg";
import img5 from "../../../assets/Image/Details5/Photo5.jpg";
import img6 from "../../../assets/Image/Details5/Photo6.jpg";
import {
    faArrowLeft,
    faLeaf,
    faMagnifyingGlassDollar,
    faCouch,
} from "@fortawesome/free-solid-svg-icons";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const images = [img1, img2, img3, img4, img5];
const images2 = [img1, img2, img3, img4, img5, img6];

const DetailsId5 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const navigate = useNavigate();

    const handleImageClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };
    return (
        <div>
            <div className="px-48 xl:px-28 lg:px-24 md:px-14 sm:px-6">
                <button
                    className="w-10 h-10 mt-1 bg-customGray text-black rounded-full p-2 tansition duration-300 ease-in-out transform hover:text-customPink hover:bg-white hover:scale-125"
                    onClick={() => navigate("/acheter-louer")}
                >
                    <FontAwesomeIcon
                        className="m-auto text-base"
                        icon={faArrowLeft}
                    />
                </button>
                <Pictures
                    title="Maison en location"
                    images={images}
                    onImageClick={handleImageClick}
                />
                <BottomPictures
                    place="Maison 5 pièces Bonneuils Matours"
                    state="900€"
                />
                <h5 className="text-2xl mt-20 mb-10">Points forts du bien</h5>
                <div className="mb-20 flex justify-around items-center reveal-left lg:flex-wrap gap-8 md:flex-wrap gap-8 sm:flex-wrap gap-8">
                    <StrongPoint icon={faLeaf} text="Jardin" />
                    <StrongPoint
                        icon={faMagnifyingGlassDollar}
                        text="Proche de commerce"
                    />
                    <StrongPoint icon={faCouch} text="Meublée" />
                </div>
                <Description
                    text="Nous vous proposons cette charmante maison ancienne meublée de 120m² habitable sur un terrain de 569m² située sur une place très calme à proximité des commerces.
                    Calme, lumineuse et très bien disposée, elle se compose au rez de chaussée d'une entrée, spacieux séjour avec cheminée, cuisine séparée ouvrant sur le jardin, salle à manger d'été, toilette.
                    Un grand garage avec mezzanine complète la partie rez de chaussée. Espace entièrement modulable.
                    A l'étage vous trouverez 4 chambres avec rangements et une salle d'eau.
                    Grenier isolé en 2015.
                    Beau jardin sur l'arrière de la maison pour profiter des beaux jours avec une grange au fond de ce dernier."
                />
            </div>
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <button
                        className="absolute top-4 right-4 text-white text-2xl"
                        onClick={() => setIsOpen(false)}
                    >
                        &times;
                    </button>
                    <div className="w-8/12 md:w-3/4 lg:w-3-4 sm:w-full">
                        <Carousel
                            selectedItem={photoIndex}
                            showThumbs={true}
                            showStatus={false}
                            swipeable={true}
                            autoPlay={true}
                            interval={4000}
                            infiniteLoop
                            useKeyboardArrows
                            onChange={(index) => setPhotoIndex(index)}
                        >
                            {images2.map((img, index) => (
                                <div key={index}>
                                    <img
                                        src={img}
                                        alt={`Show ${index + 1}`}
                                        className="object-contain w-full"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailsId5;
