import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Pictures from "../DetailsComponents/Pictures";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BottomPictures from "../DetailsComponents/BottomPictures";
import Description from "../DetailsComponents/Description";
import StrongPoint from "../DetailsComponents/StrongPoint";
import img1 from "../../../assets/Image/Details7/Photo1.jpg";
import img2 from "../../../assets/Image/Details7/Photo2.jpg";
import img3 from "../../../assets/Image/Details7/Photo3.jpg";
import img4 from "../../../assets/Image/Details7/Photo4.jpg";
import img5 from "../../../assets/Image/Details7/Photo5.jpg";
import img6 from "../../../assets/Image/Details7/Photo6.jpg";
import img7 from "../../../assets/Image/Details7/Photo7.jpg";
import img8 from "../../../assets/Image/Details7/Photo8.jpg";
import img9 from "../../../assets/Image/Details7/Photo9.jpg";
import {
    faArrowLeft,
    faHouse,
    faHouseMedical,
    faTree,
} from "@fortawesome/free-solid-svg-icons";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const images = [img1, img2, img3, img4, img5];
const images2 = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

const DetailsId7 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const navigate = useNavigate();

    const handleImageClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };
    return (
        <div>
            <div className="px-48 xl:px-28 lg:px-24 md:px-14 sm:px-6">
                <button
                    className="w-10 h-10 mt-1 bg-customGray text-black rounded-full p-2 tansition duration-300 ease-in-out transform hover:text-customPink hover:bg-white hover:scale-125"
                    onClick={() => navigate("/acheter-louer")}
                >
                    <FontAwesomeIcon
                        className="m-auto text-base"
                        icon={faArrowLeft}
                    />
                </button>
                <Pictures
                    title="Maison à vendre"
                    images={images}
                    onImageClick={handleImageClick}
                />
                <BottomPictures
                    place="Maison de plain-pied située à Vouillé"
                    state="237 000€"
                />
                <h5 className="text-2xl mt-20 mb-10">Points forts du bien</h5>
                <div className="mb-20 flex justify-around items-center reveal-left lg:flex-wrap gap-8 md:flex-wrap gap-8 sm:flex-wrap gap-8">
                    <StrongPoint icon={faHouse} text="Maison de plain-pied" />
                    <StrongPoint icon={faHouseMedical} text="Parfait état" />
                    <StrongPoint icon={faTree} text="Jardin arboré" />
                </div>
                <Description
                    text="Nous vous proposons cette charmante maison construite en 2012 de 95m² habitable sur un terrain clos et arboré de 755m². Calme, bien disposée et baignée de lumière elle se compose d'une entrée avec rangements, spacieuse pièce de vie traversante équipée d'une climatisation réversible (orientation est - ouest) ouvrant sur une belle terrasse, cuisine ouverte aménagée et équipée, trois chambres avec rangements, une salle d'eau, WC séparé, un coin buanderie avec rangements.
                    Possibilité de garer des véhicules à l'avant et sur le côté de la maison. Jardin entourant la maison.
                    Un chalet de jardin permettant de stocker outils, mobilier de jardin, vélos complète ce bien rare sur le secteur"
                />
            </div>
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <button
                        className="absolute top-4 right-4 text-white text-2xl"
                        onClick={() => setIsOpen(false)}
                    >
                        &times;
                    </button>
                    <div className="w-8/12 md:w-3/4 lg:w-3-4 sm:w-full">
                        <Carousel
                            selectedItem={photoIndex}
                            showThumbs={true}
                            showStatus={false}
                            swipeable={true}
                            autoPlay={true}
                            interval={4000}
                            infiniteLoop
                            useKeyboardArrows
                            onChange={(index) => setPhotoIndex(index)}
                        >
                            {images2.map((img, index) => (
                                <div key={index}>
                                    <img
                                        src={img}
                                        alt={`Show ${index + 1}`}
                                        className="object-contain w-full"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailsId7;
