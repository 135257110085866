import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Pictures from "../DetailsComponents/Pictures";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BottomPictures from "../DetailsComponents/BottomPictures";
import Description from "../DetailsComponents/Description";
import StrongPoint from "../DetailsComponents/StrongPoint";
import img1 from "../../../assets/Image/Details6/Photo1.jpg";
import img2 from "../../../assets/Image/Details6/Photo2.jpg";
import img3 from "../../../assets/Image/Details6/Photo3.jpg";
import img4 from "../../../assets/Image/Details6/Photo4.jpg";
import img5 from "../../../assets/Image/Details6/Photo5.jpg";
import {
    faArrowLeft,
    faLocation,
    faPaintRoller,
    faUmbrellaBeach,
} from "@fortawesome/free-solid-svg-icons";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const images = [img1, img2, img3, img4, img5];

const DetailsId6 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const navigate = useNavigate();

    const handleImageClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };
    return (
        <div>
            <div className="px-48 xl:px-28 lg:px-24 md:px-14 sm:px-6">
                <button
                    className="w-10 h-10 mt-1 bg-customGray text-black rounded-full p-2 tansition duration-300 ease-in-out transform hover:text-customPink hover:bg-white hover:scale-125"
                    onClick={() => navigate("/acheter-louer")}
                >
                    <FontAwesomeIcon
                        className="m-auto text-base"
                        icon={faArrowLeft}
                    />
                </button>
                <Pictures
                    title="Appartement"
                    images={images}
                    onImageClick={handleImageClick}
                />
                <BottomPictures
                    place="Appartement plein centre ville Chauvigny"
                    state="60 000€"
                />
                <h5 className="text-2xl mt-20 mb-10">Points forts du bien</h5>
                <div className="mb-20 flex justify-around items-center reveal-left lg:flex-wrap gap-8 md:flex-wrap gap-8 sm:flex-wrap gap-8">
                    <StrongPoint icon={faLocation} text="Localisation" />
                    <StrongPoint
                        icon={faPaintRoller}
                        text="Entièrement à rénover"
                    />
                    <StrongPoint icon={faUmbrellaBeach} text="Terrasse" />
                </div>
                <Description
                    text="Nous vous proposons cet appartement en duplex de 2 ou 3 chambres situé en plein centre-ville de Chauvigny, au 1er étage d'une petite copropriété.
                    L'appartement entièrement à renover peut se concevoir de la manière suivante :
                    Sur son premier niveau une spacieuse pièce de vie, cuisine ouverte, deux chambres avec accès balcon, une salle de bain avec WC.
                    A l'étage un espace aménageable en chambre."
                />
            </div>
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <button
                        className="absolute top-4 right-4 text-white text-2xl"
                        onClick={() => setIsOpen(false)}
                    >
                        &times;
                    </button>
                    <div className="w-8/12 md:w-3/4 lg:w-3-4 sm:w-full">
                        <Carousel
                            selectedItem={photoIndex}
                            showThumbs={true}
                            showStatus={false}
                            swipeable={true}
                            autoPlay={true}
                            interval={4000}
                            infiniteLoop
                            useKeyboardArrows
                            onChange={(index) => setPhotoIndex(index)}
                        >
                            {images.map((img, index) => (
                                <div key={index}>
                                    <img
                                        src={img}
                                        alt={`Show ${index + 1}`}
                                        className="object-contain w-full"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailsId6;
