import React from "react";
import styled from "styled-components";
import Card from "../../Card";
import imgDetails1 from "../../../assets/Image/Details1/Photo1.jpg";
import imgDetails2 from "../../../assets/Image/Details2/Photo1.jpg";
import imgDetails3 from "../../../assets/Image/Details3/Photo1.jpg";
import imgDetails4 from "../../../assets/Image/Details4/Photo1.jpg";
import imgDetails5 from "../../../assets/Image/Details5/Photo1.jpg";
import imgDetails6 from "../../../assets/Image/Details6/Photo1.jpg";
import { useNavigate } from "react-router-dom";

const DiscoverButton = styled.a`
    background-color: #a81d6a;
    color: white;
    border-radius: 5px;
    padding: 10px 10px;
    margin: 30px auto 0 auto;
    cursor: pointer;
    max-width: 189px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.01);
    }
`;

const CurrentSale = () => {
    const navigate = useNavigate();

    const handleCardClick = (id) => {
        navigate(`/details/${id}`);
    };

    return (
        <div className="flex flex-col my-24 mx-auto px-48 border xl:px-12 lg:px-10 md:px-8 sm:px-7">
            <h3 className="text-2xl mb-5 reveal-left">En vente actuellement</h3>
            <div className="flex flex-wrap justify-start gap-4 ml-10 reveal-right sm:ml-0 sm:flex-col">
                <Card
                    id="1"
                    price="Sous offre"
                    status="Acheter"
                    img={imgDetails1}
                    title="Appartement"
                    description="Appartement 4 pièces centre ville poitiers"
                    adress="Place du marché Notre Dame"
                    onClick={() => handleCardClick(1)}
                />
                <Card
                    id="2"
                    price="197 000€"
                    status="Acheter"
                    img={imgDetails2}
                    title="Appartement"
                    description="Appartement 4 pièces centre ville poitiers"
                    adress="Place du marché Notre Dame"
                    onClick={() => handleCardClick(2)}
                />
                <Card
                    id="3"
                    price="375 000€"
                    status="Acheter"
                    img={imgDetails3}
                    title="Maison"
                    description="Maison 7 pièces centre ville poitiers"
                    adress="Grand'Rue"
                    onClick={() => handleCardClick(3)}
                />
                <Card
                    id="4"
                    price="294 000€"
                    status="Acheter"
                    img={imgDetails4}
                    title="Maison"
                    description="Maison 4 pièces"
                    adress="Naintré"
                    onClick={() => handleCardClick(4)}
                />
                <Card
                    id="5"
                    price="900€"
                    status="Louer"
                    img={imgDetails5}
                    title="Maison en location"
                    description="Maison 5 pièces"
                    adress="Bonneuils Matours"
                    onClick={() => handleCardClick(5)}
                />
                <Card
                    id="6"
                    price="66 000€"
                    status="Acheter"
                    img={imgDetails6}
                    title="Appartement"
                    description="Appartement plein centre ville Chauvigny"
                    adress="Chauvigny"
                    onClick={() => handleCardClick(6)}
                />
            </div>
            <DiscoverButton href="/acheter-louer">Voir plus</DiscoverButton>
        </div>
    );
};

export default CurrentSale;
