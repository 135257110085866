import React from "react";

const Pictures = ({ images, title, onImageClick }) => {
    return (
        <div className="mt-4">
            <h4 className="mb-6 text-3xl">{title}</h4>
            <div className="grid grid-cols-2 min-h-[370px] gap-2 sm:grid-cols-1">
                <div className="col-span-1 row-span-2">
                    <img
                        className="object-cover w-full h-full cursor-pointer"
                        src={images[0]}
                        alt="show"
                        onClick={() => onImageClick(0)}
                    />
                </div>
                <div className="grid grid-cols-2 grid-rows-2 gap-2 col-span-1 row-span-2">
                    {images.slice(1).map((img, index) => (
                        <img
                            key={index}
                            className="object-cover w-full h-full cursor-pointer"
                            src={img}
                            alt="show"
                            onClick={() => onImageClick(index + 1)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Pictures;
